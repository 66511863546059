import React, { useContext } from "react";

import DogSpaElsieContext from "../contexts/DogSpaElsieContext";

const BookNow = () => {

  const {
    isMediumScreen,
    isLargeScreen,
    phrases,
    language,
  } = useContext(DogSpaElsieContext);

  return (
    <div className="booknow text-center my-6 black py-4">
      <h2
        className="black mt-3"
        style={{
          fontSize: isLargeScreen
          ? "4rem"
          : isMediumScreen
            ? "3rem"
            : "2rem",
          fontWeight: "bold"
        }}
      >
        {phrases[language]["book_now"]}
      </h2>
      <h4
        className="py-3 black"
        style={{ fontWeight: "bold" }}
      >
        +421 908 793 754
      </h4>
    </div>
  );
};

export default BookNow;