import React, { useContext } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import DogSpaElsieContext from "../contexts/DogSpaElsieContext";


const Welcome = () => {

  const {
    isExtraLargeScreen,
    phrases,
    scale,
    language,
  } = useContext(DogSpaElsieContext);

  return (
    <div>
      <Container>
        <Row>
          <Col xs={12} lg={4} xl={3}>
            {isExtraLargeScreen && (
              <h1
                className="text-nowrap"
                style={{ zIndex: "99", fontSize: scale }}
              >
                SPA DAY
              </h1>
            )}
            <div className="mx-5 my-3">
              <h2 style={{ fontSize: "5rem" }}>{phrases[language]["hello"]}</h2>
              <div
                style={{
                  color: "white",
                }}
              >
                <div className="my-3">
                  {phrases[language]["welcome"]["section_1"]}
                </div>
                <div className="my-3">
                  {phrases[language]["welcome"]["section_2"]}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={8} xl={9}>
            <Image
              fluid
              src={process.env.PUBLIC_URL + '/images/welcome_image.png'}
              style={{
                marginTop: isExtraLargeScreen ? "-150px" : "",
                zIndex: "0"
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Welcome;