import React, { useContext } from "react";
import { Container, Image, NavDropdown } from "react-bootstrap";

import DogSpaElsieContext from "../contexts/DogSpaElsieContext";


const Header = () => {

  const {
    phrases,
    language,
    setLanguage,
  } = useContext(DogSpaElsieContext);

  return (
    <header>
      <Container>
        <div className="d-flex justify-content-between py-5">
          <div className="d-flex">
            <Image
              src={process.env.PUBLIC_URL + '/images/ELSIE_mainlogo_WHITE.svg'}
              width="150px"
            />
            <h5 className="my-auto ms-5">
              {phrases[language]["location"]}
            </h5>
          </div>

          {!!language && (
            <div className="my-auto">
              <NavDropdown title={language.toUpperCase()} id="basic-nav-dropdown" className="my-auto">
                <NavDropdown.Item
                  onClick={() => setLanguage('hu')}
                >
                  <div>
                    <Image
                      className="me-2"
                      onClick={() => setLanguage('hu')}
                      src={process.env.PUBLIC_URL + '/images/hu_flag.svg'}
                      width="25px"
                    />
                    Magyar
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setLanguage('sk')}
                >
                  <div>
                    <Image
                      className="me-2"
                      onClick={() => setLanguage('sk')}
                      src={process.env.PUBLIC_URL + '/images/sk_flag.svg'}
                      width="25px"
                    />
                    Slovenčina
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setLanguage('en')}
                >
                  <div>
                    <Image
                      className="me-2"
                      onClick={() => setLanguage('en')}
                      src={process.env.PUBLIC_URL + '/images/us_flag.svg'}
                      width="25px"
                    />
                    English
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          )}
        </div>
      </Container>
    </header>
  );
}

export default Header;