import React, { useContext } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import DogSpaElsieContext from "../contexts/DogSpaElsieContext";

const About = () => {

  const {
    isLargeScreen,
    phrases,
    language,
  } = useContext(DogSpaElsieContext);

  return (
    <div className="my-5">
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <h2
              className="m-3"
              style={{ fontSize: isLargeScreen ? "4rem" : "3rem" }}
            >
              {phrases[language]["about_kati"]}
            </h2>
            <div className="mx-3">
              <p>{phrases[language]["about"]["section_1"]}</p>
              <p>{phrases[language]["about"]["section_2"]}</p>
              <p>{phrases[language]["about"]["section_3"]}</p>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <Image
              fluid
              src={process.env.PUBLIC_URL + '/images/owner.png'}
              style={{
                zIndex: "0"
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;