const PHRASES = {
  en: {
    about_kati: "About Kati",
    book_now: "Book your appointment",
    hello: 'Hello!',
    location: "Nová Stráž",
    spa_services: "spa services",
    welcome: {
      section_1: "Welcome to our doggy wonderland, where tails wag and fur gets fabulous!",
      section_2: "At Dog Spa Elsie, we believe in treating your furry friends to the ultimate spa experience. Our services are designed to make tails wag and pups strut their stuff with pride.",
    },
    about: {
      section_1: "Welcome to the Dog Spa Elsie website in the village of Nová Stráž. Since my childhood, I have admired animals, and now I would like to share this passion with you. I approach our four-legged friends with love and care, as if they were my own.",
      section_2: "I provide a wide range of basic services, including grooming, brushing, thinning of the coat, nail care, cleaning of eyes and ears, bathing, and paw care.",
      section_3: "I hope that at Dog Spa Elsie, you will find the care and quality that your beloved pet deserves. I look forward to welcoming not only old but also new four-legged companions!",
    },
    prices: {
      price_list: "Price List",
      dog_size: "Dog Size",
      tartar: "Dental Hygiene",
      tartar_description: "+ 10€ for interchangeable head, each has its own, reusable",
      bathing_and_drying: "Bathing + Drying",
      trimming: "Trimming",
      cutting: "Cutting",
      cutting_and_bathing: "Cutting + Bathing",
      hydromassage_aroma: "Hydromassage + Aroma",
      hydromassage_cutting: "Hydromassage + Cutting",
      ozone_therapy: "Ozone Therapy",
      ozone_therapy_and_cutting: "Ozone Therapy + Cutting",
      ozone_therapy_and_trimming: "Ozone Therapy + Trimming",
      included_extras: "Each procedure includes: ear cleaning, eye care, nail trimming, paw massage, and light perfume.",
      trimming_time: "The trimming time may vary depending on the current condition of the dog's coat. Therefore, the price starts from €20 per started hour.",
      rasta: "If the coat is tangled, making grooming difficult, please note that we charge an additional €10 for each started hour.",
      price_description: "Prices are indicative, and the final fees will depend on the size of the dog, the condition of its coat, its behavior, and the services provided.",
    },
    services: {
      tartar: {
        title: "Tartar Removal",
        section_1: "Tartar removal at Dog Spa Elsie is painless, anesthesia-free, and extremely effective. Our ultrasonic device penetrates deep into the gums, gently loosening the tartar. The brush during the procedure is completely silent, ensuring a pleasant experience for your canine companions. The real challenge comes when it's necessary to delicately scrape the tartar from the surface of the teeth. Since this cannot be done permanently, periodic repetition of the treatment is recommended for optimal oral hygiene for your dog.",
      },
      hydrotherapy: {
        title: "Hydrotherapy",
        section_1: "Versatile in its application for treating various illnesses, it also alleviates stress in animals and provides effective relief for joint pain. It is combined with aromatherapy, where aromatic oils not only support skin immunity but also enhance mental well-being. The effect of hydrotherapy can be further heightened with ozone therapy.",
      },
      ozone_therapy: {
        title: "Ozone Therapy",
        section_1: "Ozone therapy is a relaxing and healing procedure. Ozone is a special type of oxygen with aseptic, bactericidal, and antiviral properties. It is often used as a disinfectant in water purification. Air bubbles falling onto the skin during this process massage it, enhancing the effects of hydrotherapy. Ozone therapy is employed to combat skin mycoses, such as lichen, accelerate wound healing, and aid in cardiovascular conditions by improving oxygen delivery to the blood. Additionally, it supports the treatment of the lymphatic, cardiovascular, and circulatory systems, eases breathing, and alleviates stress. Ozone therapy promotes regeneration after intense physical and mental exertion. It is an excellent preventive measure against excessive hair shedding, appreciated by every owner. This procedure is suitable for both furry and hairless dogs.",
      },
    },
  },
  sk: {
    about_kati: "Katarína",
    book_now: "Rezervácia",
    hello: 'Vitajte!',
    location: "Nová Stráž",
    spa_services: "služby",
    welcome: {
      section_1: "Vitajte na stránke Dog Spa Elsie!",
      section_2: "Potešte svojho miláčika nezabudnuteľným kúpeľným zážitkom, kde starostlivosť o srst a pohodlie sú na prvom mieste.",
    },
    about: {
      section_1: "Vitajte na stránke psej kozmetiky Dog Spa Elsie v obci Nová Stráž. Už od malička som obdivovala zvieratá, a teraz by som s vami rada zdieľala túto vášeň. K Vašim štvornohým priateľom pristupujem vždy s láskou a starostlivosťou, akoby boli moji vlastní.",
      section_2: "Poskytujem širokú škálu základných služieb vrátane strihania, česania, zriedenia srsti, starostlivosti o pazúre, čistenia očí a uší, kúpeľa a starostlivosti o labky.",
      section_3: "Dúfam, že v Dog Spa Elsie nájdete starostlivosť a kvalitu, ktorú si váš miláčik zaslúži. S radosťou očakávam nielen starých, ale aj nových štvornohých spoločníkov!",
    },
    prices: {
      price_list: "Cenník",
      dog_size: "Veľkosť psa",
      tartar: "Dentálna hygiena",
      tartar_description: "+ 10€ vymeniteľná hlavica, každý má vlastnú, opakovane použiteľná",
      bathing_and_drying: "Kúpanie + sušenie",
      trimming: "Trimovanie",
      cutting: "Strihanie",
      cutting_and_bathing: "Strihanie + kúpanie",
      hydromassage_aroma: "Hydromasáž + aroma",
      hydromassage_cutting: "Hydromasáž + strihanie",
      ozone_therapy: "Ozónoterapia",
      ozone_therapy_and_cutting: "Ozónoterapia + strihanie",
      ozone_therapy_and_trimming: "Ozónoteria + trimovanie",
      included_extras: "Každá procedúra zahŕňa: čistenie uší, ošetrovanie očí, strihanie pazúrov, masáž labiek a jemné parfumovanie.",
      trimming_time: "Čas trimovania sa môže líšiť v závislosti od aktuálneho stavu srsti psa. Preto sa cena pohybuje od 20 € za začatú hodinu.",
      rasta: "Ak je srst zamotaná, čo komplikuje starostlivosť, prosím, zohľadnite, že za každú začatú hodinu účtujeme dodatočný poplatok 10 €.",
      price_description: "Ceny sú informatívne a konečné poplatky budú závisieť od veľkosti psa, stavu jeho srsti, jeho správania a poskytovaných služieb.",
    },
    services: {
      tartar: {
        title: "Odstránenie zubného kameňa",
        section_1: "Odstránenie zubného kameňa v Dog Spa Elsie je bezbolestné, bez anestézie a mimoriadne efektívne. Naše ultrazvukové zariadenie preniká hlboko do ďasien, jemne uvoľňujúc zubný kameň. Počas procedúry je kefa úplne tichá, čo zabezpečuje príjemný zážitok pre vašich psích miláčikov. Pravá výzva prichádza vtedy, keď je potrebné jemne skracovať zubný kameň z povrchu zubov. Keďže to nie je možné urobiť trvalo, odporúča sa periodické opakovanie liečby pre optimálnu ústnu hygienu vášho psa.",
      },
      hydrotherapy: {
        title: "Hydroterapia",
        section_1: "Univerzálne využiteľné na liečbu rôznych ochorení, pričom zároveň zmiernuje stres u zvierat a efektívne pomáha pri bolestiach kĺbov. Je kombinované s aromaterapiou, pri ktorej aromatické oleje nielenže podporujú imunitu kože, ale tiež posilňujú duševné zdravie. Účinok hydroterapie sa dá ešte zvýšiť ozónovou terapiou.",
      },
      ozone_therapy: {
        title: "Ozónová terapia",
        section_1: "Ozónová terapia je uvoľňujúci a liečebný postup. Ozón je špeciálny druh kyslíka s aseptickými, baktericídnymi a virucidálnymi vlastnosťami. Často sa používa ako dezinfekčný prostriedok pri čistení vody. Bubliny vzduchu padajúce na kožu pri tomto procese ju masírujú, čo zvyšuje účinok hydroterapie. Ozónová terapia sa využíva pri boji proti mykózam na koži, ako napríklad pri lúpaní, urýchľuje hojenie rán, a pomáha pri srdcovo-cievnych ochoreniach tým, že zlepšuje prívod kyslíka do krvi. Okrem toho podporuje liečbu lymfatického, srdcového a cievneho systému, uľahčuje dýchanie a zmierňuje stres. Ozónová terapia napomáha regenerácii po vysokom fyzickom a psychickom zaťažení. Je vynikajúcim prostriedkom na prevenciu nadmerného vypadávania srsti, čo ocení každý majiteľ. Tento postup je vhodný pre psíkov s huňatou srstou alebo bez nej.",
      },
    },
  },
  hu: {
    about_kati: "Kati",
    book_now: "Időpontfoglalás",
    location: "Örsújfalu",
    spa_services: "szolgáltatások",
    hello: 'Helló!',
    welcome: {
      section_1: "Üdvözlöm a Dog Spa Elsie oldalán!",
      section_2: "Kényeztesse kedvencét egy felejthetetlen spa élménnyel, ahol a szőrzet gondozása és a kényelem az elsődleges.",
    },
    about: {
      section_1: "Üdvözlöm Önöket a Dog Spa Elsie kutyaszalon oldalán Örsújfalun. Már kiskorom óta rajongok az állatokért, és ez az a szenvedély, amit most szeretnék megosztani Önökkel. Mindig olyan gondoskodással és szeretettel fordulok a négylábú kedvencekhez, mintha sajátjaim lennének.",
      section_2: "Széles körű alapszolgáltatásokat kínálok, amelyek magukban foglalják a nyírást, fésülést, ritkítást, karomvágást, szem- és fültisztítást, fürdetést, valamint a mancsápolást.",
      section_3: "Remélem, hogy a Dog Spa Elsie-ben megtalálják azt a gondoskodást és minőséget, amit kedvencük megérdemel. Örömmel várom mind a régi, mind az új kutyabarátokat!",
    },
    prices: {
      price_list: "Árlista",
      dog_size: "Kutya mérete",
      tartar: "Fogkő eltávolítása",
      tartar_description: "+ 10€ cserélhető fej, mindenkinek saját, többször használható",
      bathing_and_drying: "Fürdetés + szárítás",
      trimming: "Trimmelés",
      cutting: "Nyírás",
      cutting_and_bathing: "Nyírás + fürdetés",
      hydromassage_aroma: "Hidromasszázs + aroma",
      hydromassage_cutting: "Hidromasszázs + nyírás",
      ozone_therapy: "Ózonterápia",
      ozone_therapy_and_cutting: "Ózonterápia + nyírás",
      ozone_therapy_and_trimming: "Ózonterápia + trimmelés",
      included_extras: "Minden kezelés magában foglalja: fültisztítást, szemápolást, karomvágást, talpmasszázst, valamint finom parfümözést.",
      trimming_time: "A trimmelés időtartama változhat a kutya szőrzetének aktuális állapota szerint. Ezért az ár 20 €-tól / kezdett óra.",
      rasta: "Amennyiben a szőrzet be van rasztásodva, ami nehezíti az ápolást, kérjük, vegyék figyelembe, hogy minden megkezdett óráért 10 € felárat számolunk.",
      price_description: "Az árak tájékoztató jellegűek, és a végső díjakat a kutyák mérete, szőrzetének állapota, viselkedése, valamint a kívánt szolgáltatások határozzák meg.",
    },
    services: {
      tartar: {
        title: "Fogkő-eltávolítás",
        section_1: "A fogkő eltávolítása a Dog Spa Elsie-ben fájdalommentes, altatásmentes és rendkívül hatékony. Az ultrahangos eszközünk mélyen hatol az ínybe, lágyan lazítva fel a fogkövet. A kezelés során a kefe teljesen zajtalan, így kutyusai kellemesen viselik azt. Az igazi kihívás akkor következik, amikor a fogakról finoman le kell kaparni a fogkövet. Mivel ez nem történhet meg véglegesen, időről időre ajánlott megismételni a kezelést a kutyusok optimális szájhigiéniájának érdekében.",
      },
      hydrotherapy: {
        title: "Hidroterápia",
        section_1: "Bizonyos betegségek széles körű kezelésére alkalmazható, ráadásul enyhíti az állatok stresszét és hatékony segítséget nyújt az ízületi fájdalmaknál. A kezelés részeként aromaterápia is alkalmazásra kerül, ahol az aromaolajok nemcsak támogatják a bőr immunitását, de erősítik a mentális egészséget is. Mindezt tovább fokozza a hidromasszázs, amelynek hatását az ózonterápia is kiegészíti.",
      },
      ozone_therapy: {
        title: "Ózonterápia",
        section_1: "Az ózonterápia egy nyugtató és gyógyító eljárás, amely különleges oxigénfajtát alkalmaz, ami aszeptikus, baktericid és vírusellenes tulajdonságokkal rendelkezik. Ezt az eljárást gyakran használják vízfertőtlenítőként is. A légbuborékok, amelyek a bőrre érkeznek, növelik a hidromasszázs hatását, miközben kényeztetik azt. Az ózonterápia segítségével hatékonyan küzdhetünk a bőr mikózisai, például a zuzmó, ellen, felgyorsíthatjuk a sebgyógyulást, és támogathatjuk a szív- és érrendszer működését, javítva ezzel a vér oxigénellátását. Emellett segít a nyirok-, szív- és érrendszeri problémákban, könnyíti a légzést, és enyhíti a stresszt. Az ózonterápia kiválóan segíti a regenerációt intenzív fizikai és lelki terhelés esetén. Rendkívül hatékony megelőzés is lehet a túlzott szőrhullás ellen, amit minden állattartó értékelni fog. Ez a kezelés ideális mind a szőrös, mind a meztelen kutyusok számára.",
      },
    },
  }
};

export default PHRASES;