import React, { useEffect, useState }  from 'react';

import About from "../About/About";
import BookNow from "../BookNow/BookNow";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Services from "../Services/Services";
import Splash from "../Splash/Splash";
import Welcome from "../Welcome/Welcome";

import DogSpaElsieContext from "../contexts/DogSpaElsieContext";
import phrases from '../../translations/phrases';

const Main = () => {

  const [language, setLanguage] = useState("sk");
  const [width, setWidth] = useState(window.innerWidth);

  console.log(language)

  const isMediumScreen = width >= 768;
  const isLargeScreen = width >= 992;
  const isExtraLargeScreen = width >= 1200;

  let scale;
  if (isExtraLargeScreen) {
    scale = "900%"
  } else if (isLargeScreen) {
    scale = "700%"
  } else if (isMediumScreen) {
    scale = "500%"
  } else {
    scale = "300%"
  }

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions)
  }, []);

  const context = {
    isMediumScreen,
    isLargeScreen,
    isExtraLargeScreen,
    language,
    setLanguage,
    phrases,
    scale,
  };

  return (
    <div>
      <DogSpaElsieContext.Provider value={context}>
          <Header />
          <Welcome />
          <Services />
          <BookNow />
          <About />
          <Footer />
      </DogSpaElsieContext.Provider>
    </div>
  );
}

export default Main;