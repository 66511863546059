import React, { useContext } from "react";
import { Col, Container, Row, Table } from 'react-bootstrap';

import DogSpaElsieContext from "../contexts/DogSpaElsieContext";

const Services = () => {

  const {
    isLargeScreen,
    phrases,
    language,
  } = useContext(DogSpaElsieContext);

  return (
    <Container className="my-5">
      <Row>
        <Col xs={12} lg={6}>
          <h1
            className="mb-5 mx-3"
            style={{ fontSize: isLargeScreen ? "4rem" : "3rem" }}
          >
            {phrases[language]["spa_services"]}
          </h1>
        </Col>
        <Col xs={12} lg={6}>
          <div className="mb-5 mx-3">
            <h4 className="mb-3">{phrases[language]["services"]["tartar"]["title"]}</h4>
            <p>{phrases[language]["services"]["tartar"]["section_1"]}</p>
          </div>

          <div className="my-5 mx-3">
            <h4 className="mb-3">{phrases[language]["services"]["hydrotherapy"]["title"]}</h4>
            <p>{phrases[language]["services"]["hydrotherapy"]["section_1"]}</p>
          </div>

          <div className="my-5 mx-3">
            <h4 className="mb-3">{phrases[language]["services"]["ozone_therapy"]["title"]}</h4>
            <p>{phrases[language]["services"]["ozone_therapy"]["section_1"]}</p>
          </div>
        </Col>
      </Row>

      <div className="my-5">
        <h3>{phrases[language]["prices"]["price_list"]}</h3>
        <Table striped>
          <thead>
            <tr>
              <th>{phrases[language]["prices"]["dog_size"]}</th>
              <th>S</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {phrases[language]["prices"]["tartar"]}
                <div>
                  {phrases[language]["prices"]["tartar_description"]}
                </div>
              </td>
              <td>25€</td>
              <td>30€</td>
              <td>30€</td>
              <td>-</td>
            </tr>
            <tr>
              <td>{phrases[language]["prices"]["bathing_and_drying"]}</td>
              <td>20€</td>
              <td>25€</td>
              <td>30€</td>
              <td>40€</td>
            </tr>
            <tr>
              <td>{phrases[language]["prices"]["trimming"]}</td>
              <td>25€</td>
              <td>30€</td>
              <td>35€</td>
              <td>40€</td>
            </tr>
            <tr>
              <td>{phrases[language]["prices"]["cutting"]}</td>
              <td>20€</td>
              <td>25€</td>
              <td>30€</td>
              <td>35€</td>
            </tr>
            <tr>
              <td>{phrases[language]["prices"]["cutting_and_bathing"]}</td>
              <td>25€</td>
              <td>30€</td>
              <td>35€</td>
              <td>40€</td>
            </tr>
            <tr>
              <td>{phrases[language]["prices"]["hydromassage_aroma"]}</td>
              <td>30€</td>
              <td>35€</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>{phrases[language]["prices"]["hydromassage_cutting"]}</td>
              <td>45€</td>
              <td>50€</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>{phrases[language]["prices"]["ozone_therapy"]}</td>
              <td>35€</td>
              <td>40€</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>{phrases[language]["prices"]["ozone_therapy_and_cutting"]}</td>
              <td>50€</td>
              <td>55€</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>{phrases[language]["prices"]["ozone_therapy_and_trimming"]}</td>
              <td>55€</td>
              <td>60€</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <p className="mx-3">
        {phrases[language]["prices"]["included_extras"]}{" "}
        {phrases[language]["prices"]["trimming_time"]}{" "}
        {phrases[language]["prices"]["rasta"]}{" "}
        {phrases[language]["prices"]["price_description"]}
      </p>

    </Container>
  );
};

export default Services;