import React from "react";

const Footer = () => {
  return (
    <footer className="footer text-center py-5">
      <div>© DOG SPA ELSIE 2023</div>
      <div>DESIGNED BY LAUREN O. VICTORIA</div>
    </footer>
  );
};

export default Footer;